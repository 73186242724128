<template>
  <div>
    <!-- 学生个人信息 -->
    <div class="header-box" />

    <!-- 个人信息表格容器 -->
    <div class="container">
      <!-- 参赛须知显示框 -->
      <div class="notice-box">
        <div class="notice-title">
          个人信息
        </div>
        <div class="notice-content">
          <!-- <div class="info-form"> -->
          <el-form v-if="!loading" ref="expertInfo" size="small" :model="form" label-width="80px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="姓名">
                  <el-input v-model="form.realName" placeholder="请填写用户姓名" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别">
                  <!-- <el-input v-model="form.gender" class="input-form" size="medium" :disabled="true" /> -->
                  <el-select v-model="form.gender" style="width: 100%" placeholder="请选择用户性别">
                    <el-option label="未知" value="未知" />
                    <el-option label="男" value="男" />
                    <el-option label="女" value="女" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="出生日期">
                  <el-date-picker
                    v-model="form.birthday"
                    style="width: 100%"
                    class="input-form"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择出生日期"
                  />
                  <!-- <el-input v-model="form.birthday" /> -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号码">
                  <el-input v-model="form.phone" :disabled="true" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="身份证号">
                  <el-input v-model="form.identityNumber" placeholder="请填写身份证号" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电子邮箱">
                  <el-input v-model="form.email" placeholder="请填写电子邮箱" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="所属高校">
                  <!-- <el-input v-model="form.university" /> -->
                  <el-select v-model="form.university" style="width: 100%" filterable placeholder="请选择所属高校">
                    <el-option
                      v-for="item in universityList"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="学生类别">
                  <el-select v-model="form.type" style="width: 100%" placeholder="请选择学生类别">
                    <!-- <el-option label="未分类" value="未知" /> -->
                    <el-option label="本科生" value="本科生" />
                    <el-option label="研究生" value="研究生" />
                  </el-select>
                  <!-- <el-input v-model="form.type" class="input-form" size="medium" /> -->
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="通讯地址">
              <el-input v-model="form.address" placeholder="请填写通讯地址" />
            </el-form-item>

          </el-form>
        </div>
      </div>

      <!-- 同意按钮 -->
      <div class="consent">
        <div class="consent-box">
          <!-- <span class="announce">
                        <el-checkbox class="check svg" v-model="isConsent">我同意并做出上述承诺</el-checkbox>
                    </span> -->
          <!-- <div> -->
          <el-button style="width: 120px;" type="primary" @click="saveEdit">保存修改</el-button>
          <!-- </div> -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { getUserInfo, newUserProfile } from '@/api/usermanagement'
import { getUniversityList } from '@/utils/universities.js'
import '@/icons/consent'
import '@/icons/circle'

export default {
  data() {
    return {
      userName: '',
      loading: false,
      form: {
        realName: '',
        gender: '',
        phone: '',
        birthday: '',
        email: '',
        address: '',
        identityNumber: '',
        university: '',
        type: ''
      },

      universitylist: null
    }
  },
  created() {
    this.getUniversities()
  },
  mounted() {
    this.userName = this.globalGetUsername()
    this.init(this.userName)
  },
  methods: {
    // 初始化，获取学生的个人信息
    init(username) {
      getUserInfo(username).then(res => {
        this.form = res.data
      })
    },
    // 获取所有学校列表
    getUniversities() {
      this.universityList = getUniversityList()
    },
    // 保存个人信息修改
    saveEdit() {
      console.log('save edit')
      newUserProfile(this.userName, this.form).then(() => {
        this.$message({
          message: '信息保存成功',
          type: 'success'
        })
        // console.log(res)
      })
    }
  }
}
</script>

<style>
.el-form-item__label {
  color: #4b4b4b;
  font-weight: 400;
}

.el-input.is-disabled .el-input__inner {
  color: #606266;
  background-color: #fff;
}
</style>

<style scoped>
@import '~@/styles/theme.css';

.header-box {
  height: 60px;
}

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: var(--background-light-color);
  /* border: 1px solid red; */
  text-align: center;
}

.notice-box {
  display: inline-block;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 40px;
  height: 510px;
  width: 1000px;
  /* border: 1px solid red; */
}

.notice-title {
  text-align: center;
  margin-top: 10px;
  height: 80px;
  /* border: 1px solid red; */
  font-size: 20px;
  line-height: 80px;
}

.notice-content {
  display: inline-block;
  width: 80%;
  margin: auto;
  margin-top: 30px;
  /* border: 1px solid red; */
  text-align: left;
  height: 390px;
  overflow: auto;
}

.info-form {
  /* float: left; */
  /* border: 1px solid rgb(105, 97, 97); */
  width: 80%;
  margin: 40px auto;

}

.consent {
  display: block;
}

.consent-box {
  display: inline-block;
  margin: auto;
  margin-top: 50px;
  width: 400px;
  height: 50px;
  /* border: 1px solid red; */

}

.announce {
  float: left;
  /* display: inline-block; */
  margin-top: 5px;
  font-size: 13px;
  height: 40px;
  width: 155px;
  color: rgb(51, 51, 51);
  /* border: 1px solid red; */
}

.check /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: var(--finish-color);
  border-color: var(--finish-color);
  border-radius: 50%;
}

.check /deep/ .el-checkbox__inner {
  border-radius: 50%;
}

.svg {
  float: left;
  /* display: inline-block; */
  /* border: 1px solid white; */
  margin-right: 5px;
  margin-top: 7px;
}

.svg {
  cursor: pointer;
}

.announce-text {
  float: right;
  /* display: inline-block; */
  /* line-height: 20px; */
  margin-top: 7px;
  /* border: 1px solid white; */
}

.btn-place {
  float: left;
  margin-left: 30px;
  /* display: inline-block; */
  /* width: 200px; */
}

</style>
