export function getUniversityList() {
  const universities = [
    '北京大学',
    '中国人民大学',
    '清华大学',
    '北京交通大学',
    '北京工业大学',
    '北京航空航天大学',
    '北京理工大学',
    '北京科技大学',
    '北方工业大学',
    '北京化工大学',
    '北京工商大学',
    '北京服装学院',
    '北京邮电大学',
    '北京印刷学院',
    '北京建筑大学',
    '北京石油化工学院',
    '北京电子科技学院',
    '中国农业大学',
    '北京农学院',
    '北京林业大学',
    '北京协和医学院',
    '首都医科大学',
    '北京中医药大学',
    '北京师范大学',
    '首都师范大学',
    '首都体育学院',
    '北京外国语大学',
    '北京第二外国语学院',
    '北京语言大学',
    '中国传媒大学',
    '中央财经大学',
    '对外经济贸易大学',
    '北京物资学院',
    '首都经济贸易大学',
    '外交学院',
    '中国人民公安大学',
    '国际关系学院',
    '北京体育大学',
    '中央音乐学院',
    '中国音乐学院',
    '中央美术学院',
    '中央戏剧学院',
    '中国戏曲学院',
    '北京电影学院',
    '北京舞蹈学院',
    '中央民族大学',
    '中国政法大学',
    '华北电力大学',
    '中华女子学院',
    '北京信息科技大学',
    '中国矿业大学北京',
    '中国石油大学北京',
    '中国地质大学北京',
    '北京联合大学',
    '北京城市学院',
    '中国青年政治学院',
    '中国劳动关系学院',
    '中国科学院大学',
    '中国社会科学院大学',
    '长江商学院',
    '中共中央党校国家行政学院',
    '北京国家会计学院',
    '中国科学技术信息研究所',
    '中国财政科学研究院',
    '商务部国际贸易经济合作研究院',
    '中国农业科学院',
    '中国兽医药品监察所',
    '中国林业科学研究院',
    '中国水利水电科学研究院',
    '中国电力科学研究院',
    '中国建筑科学研究院',
    '中国城市规划设计研究院',
    '中国建筑设计研究院',
    '中国环境科学研究院',
    '中国地质科学院',
    '钢铁研究总院',
    '中冶建筑研究总院有限公司',
    '冶金自动化研究设计院',
    '机械科学研究总院',
    '北京机械工业自动化研究所',
    '北京机电研究所',
    '中国农业机械化科学研究院',
    '中国原子能科学研究院',
    '中国核电工程有限公司',
    '核工业北京地质研究院',
    '核工业北京化工冶金研究院',
    '中国工程物理研究院',
    '中国航空研究院',
    '北京航空精密机械研究所',
    '北京航空材料研究院',
    '中国航空制造技术研究院',
    '中国航空规划设计研究总院有限公司',
    '中国航空工业总公司第六二八研究所',
    '北京长城计量测试技术研究所',
    '中国电子科技集团公司电子科学研究院',
    '华北计算机系统工程研究所',
    '中国北方车辆研究所',
    '中国运载火箭技术研究院',
    '中国航天科工集团第二研究院',
    '中国航天系统科学与工程研究院',
    '中国航天科工集团第三研究院',
    '中国空间技术研究院航天五院',
    '中国航天科技集团公司第十一研究院',
    '煤炭科学研究总院',
    '中国石油勘探开发研究院',
    '北京化工研究院',
    '北京橡胶工业研究设计院',
    '轻工业环境保护研究所',
    '中国食品发酵工业研究院',
    '中国制浆造纸研究院有限公司',
    '中国铁道科学研究院',
    '交通运输部公路科学研究所',
    '电信科学技术研究院',
    '中国艺术研究院',
    '中国电影艺术研究中心',
    '中国疾病预防控制中心',
    '中国中医科学院',
    '中国食品药品检定研究院',
    '北京生物制品研究所',
    '中日友好临床医学研究所',
    '卫生部北京老年医学研究所',
    '国家体育总局体育科学研究所',
    '中国建筑材料科学研究总院',
    '中国气象科学研究院',
    '国家海洋环境预报中心',
    '中国地震局地球物理研究所',
    '中国地震局地质研究所',
    '中国地震局地震预测研究所',
    '应急管理部国家自然灾害防治研究院',
    '中国计量科学研究院',
    '中国测绘科学研究院',
    '中国舰船研究院',
    '中国石油化工股份有限公司石油化工科学研究院',
    '北京矿冶研究总院',
    '北京有色金属研究总院',
    '北京市劳动保护科学研究所',
    '北京市环境保护科学研究院',
    '北京市心肺血管疾病研究所',
    '北京市市政工程研究院',
    '北京市结核病胸部肿瘤研究所',
    '北京市创伤骨科研究所',
    '首都儿科研究所',
    '中共北京市委党校',
    '国防大学',
    '陆军装甲兵学院',
    '陆军航空兵学院',
    '陆军防化学院',
    '空军指挥学院',
    '航天工程大学',
    '军事科学院',
    '解放军医学院',
    '中国消防救援学院',
    '北京工业职业技术学院',
    '北京信息职业技术学院',
    '北京电子科技职业学院',
    '北京京北职业技术学院',
    '北京青年政治学院',
    '首钢工学院',
    '北京农业职业学院',
    '北京政法职业学院',
    '北京财贸职业学院',
    '北京北大方正软件职业技术学院',
    '北京经贸职业学院',
    '北京经济技术职业学院',
    '北京戏曲艺术职业学院',
    '北京汇佳职业学院',
    '北京科技经营管理学院',
    '首都师范大学科德学院',
    '北京工商大学嘉华学院',
    '北京科技职业学院',
    '北京培黎职业学院',
    '北京邮电大学世纪学院',
    '北京工业大学耿丹学院',
    '北京交通职业技术学院',
    '北京警察学院',
    '北京经济管理职业学院',
    '北京劳动保障职业学院',
    '北京社会管理职业学院',
    '北京艺术传媒职业学院',
    '北京第二外国语学院中瑞酒店管理学院',
    '北京体育职业学院',
    '北京交通运输职业学院',
    '北京卫生职业学院',
    '北京网络职业学院',
    '北京大学医学部',
    '北京科技大学延庆分校',
    '北京邮电大学宏福校区',
    '战略支援部队航天工程大学',
    '武警特种警察学院',
    '北京航空航天大学中法工程师学院'

  ]
  return universities
}

export function getUniversityJson() {
  const universities = [
    { 'value': '北京大学' },
    { 'value': '中国人民大学' },
    { 'value': '清华大学' },
    { 'value': '北京交通大学' },
    { 'value': '北京工业大学' },
    { 'value': '北京航空航天大学' },
    { 'value': '北京理工大学' },
    { 'value': '北京科技大学' },
    { 'value': '北方工业大学' },
    { 'value': '北京化工大学' },
    { 'value': '北京工商大学' },
    { 'value': '北京服装学院' },
    { 'value': '北京邮电大学' },
    { 'value': '北京印刷学院' },
    { 'value': '北京建筑大学' },
    { 'value': '北京石油化工学院' },
    { 'value': '北京电子科技学院' },
    { 'value': '中国农业大学' },
    { 'value': '北京农学院' },
    { 'value': '北京林业大学' },
    { 'value': '北京协和医学院' },
    { 'value': '首都医科大学' },
    { 'value': '北京中医药大学' },
    { 'value': '北京师范大学' },
    { 'value': '首都师范大学' },
    { 'value': '首都体育学院' },
    { 'value': '北京外国语大学' },
    { 'value': '北京第二外国语学院' },
    { 'value': '北京语言大学' },
    { 'value': '中国传媒大学' },
    { 'value': '中央财经大学' },
    { 'value': '对外经济贸易大学' },
    { 'value': '北京物资学院' },
    { 'value': '首都经济贸易大学' },
    { 'value': '外交学院' },
    { 'value': '中国人民公安大学' },
    { 'value': '国际关系学院' },
    { 'value': '北京体育大学' },
    { 'value': '中央音乐学院' },
    { 'value': '中国音乐学院' },
    { 'value': '中央美术学院' },
    { 'value': '中央戏剧学院' },
    { 'value': '中国戏曲学院' },
    { 'value': '北京电影学院' },
    { 'value': '北京舞蹈学院' },
    { 'value': '中央民族大学' },
    { 'value': '中国政法大学' },
    { 'value': '华北电力大学' },
    { 'value': '中华女子学院' },
    { 'value': '北京信息科技大学' },
    { 'value': '中国矿业大学北京' },
    { 'value': '中国石油大学北京' },
    { 'value': '中国地质大学北京' },
    { 'value': '北京联合大学' },
    { 'value': '北京城市学院' },
    { 'value': '中国青年政治学院' },
    { 'value': '中国劳动关系学院' },
    { 'value': '中国科学院大学' },
    { 'value': '中国社会科学院大学' },
    { 'value': '长江商学院' },
    { 'value': '中共中央党校国家行政学院' },
    { 'value': '北京国家会计学院' },
    { 'value': '中国科学技术信息研究所' },
    { 'value': '中国财政科学研究院' },
    { 'value': '商务部国际贸易经济合作研究院' },
    { 'value': '中国农业科学院' },
    { 'value': '中国兽医药品监察所' },
    { 'value': '中国林业科学研究院' },
    { 'value': '中国水利水电科学研究院' },
    { 'value': '中国电力科学研究院' },
    { 'value': '中国建筑科学研究院' },
    { 'value': '中国城市规划设计研究院' },
    { 'value': '中国建筑设计研究院' },
    { 'value': '中国环境科学研究院' },
    { 'value': '中国地质科学院' },
    { 'value': '钢铁研究总院' },
    { 'value': '中冶建筑研究总院有限公司' },
    { 'value': '冶金自动化研究设计院' },
    { 'value': '机械科学研究总院' },
    { 'value': '北京机械工业自动化研究所' },
    { 'value': '北京机电研究所' },
    { 'value': '中国农业机械化科学研究院' },
    { 'value': '中国原子能科学研究院' },
    { 'value': '中国核电工程有限公司' },
    { 'value': '核工业北京地质研究院' },
    { 'value': '核工业北京化工冶金研究院' },
    { 'value': '中国工程物理研究院' },
    { 'value': '中国航空研究院' },
    { 'value': '北京航空精密机械研究所' },
    { 'value': '北京航空材料研究院' },
    { 'value': '中国航空制造技术研究院' },
    { 'value': '中国航空规划设计研究总院有限公司' },
    { 'value': '中国航空工业总公司第六二八研究所' },
    { 'value': '北京长城计量测试技术研究所' },
    { 'value': '中国电子科技集团公司电子科学研究院' },
    { 'value': '华北计算机系统工程研究所' },
    { 'value': '中国北方车辆研究所' },
    { 'value': '中国运载火箭技术研究院' },
    { 'value': '中国航天科工集团第二研究院' },
    { 'value': '中国航天系统科学与工程研究院' },
    { 'value': '中国航天科工集团第三研究院' },
    { 'value': '中国空间技术研究院航天五院' },
    { 'value': '中国航天科技集团公司第十一研究院' },
    { 'value': '煤炭科学研究总院' },
    { 'value': '中国石油勘探开发研究院' },
    { 'value': '北京化工研究院' },
    { 'value': '北京橡胶工业研究设计院' },
    { 'value': '轻工业环境保护研究所' },
    { 'value': '中国食品发酵工业研究院' },
    { 'value': '中国制浆造纸研究院有限公司' },
    { 'value': '中国铁道科学研究院' },
    { 'value': '交通运输部公路科学研究所' },
    { 'value': '电信科学技术研究院' },
    { 'value': '中国艺术研究院' },
    { 'value': '中国电影艺术研究中心' },
    { 'value': '中国疾病预防控制中心' },
    { 'value': '中国中医科学院' },
    { 'value': '中国食品药品检定研究院' },
    { 'value': '北京生物制品研究所' },
    { 'value': '中日友好临床医学研究所' },
    { 'value': '卫生部北京老年医学研究所' },
    { 'value': '国家体育总局体育科学研究所' },
    { 'value': '中国建筑材料科学研究总院' },
    { 'value': '中国气象科学研究院' },
    { 'value': '国家海洋环境预报中心' },
    { 'value': '中国地震局地球物理研究所' },
    { 'value': '中国地震局地质研究所' },
    { 'value': '中国地震局地震预测研究所' },
    { 'value': '应急管理部国家自然灾害防治研究院' },
    { 'value': '中国计量科学研究院' },
    { 'value': '中国测绘科学研究院' },
    { 'value': '中国舰船研究院' },
    { 'value': '中国石油化工股份有限公司石油化工科学研究院' },
    { 'value': '北京矿冶研究总院' },
    { 'value': '北京有色金属研究总院' },
    { 'value': '北京市劳动保护科学研究所' },
    { 'value': '北京市环境保护科学研究院' },
    { 'value': '北京市心肺血管疾病研究所' },
    { 'value': '北京市市政工程研究院' },
    { 'value': '北京市结核病胸部肿瘤研究所' },
    { 'value': '北京市创伤骨科研究所' },
    { 'value': '首都儿科研究所' },
    { 'value': '中共北京市委党校' },
    { 'value': '国防大学' },
    { 'value': '陆军装甲兵学院' },
    { 'value': '陆军航空兵学院' },
    { 'value': '陆军防化学院' },
    { 'value': '空军指挥学院' },
    { 'value': '航天工程大学' },
    { 'value': '军事科学院' },
    { 'value': '解放军医学院' },
    { 'value': '中国消防救援学院' },
    { 'value': '北京工业职业技术学院' },
    { 'value': '北京信息职业技术学院' },
    { 'value': '北京电子科技职业学院' },
    { 'value': '北京京北职业技术学院' },
    { 'value': '北京青年政治学院' },
    { 'value': '首钢工学院' },
    { 'value': '北京农业职业学院' },
    { 'value': '北京政法职业学院' },
    { 'value': '北京财贸职业学院' },
    { 'value': '北京北大方正软件职业技术学院' },
    { 'value': '北京经贸职业学院' },
    { 'value': '北京经济技术职业学院' },
    { 'value': '北京戏曲艺术职业学院' },
    { 'value': '北京汇佳职业学院' },
    { 'value': '北京科技经营管理学院' },
    { 'value': '首都师范大学科德学院' },
    { 'value': '北京工商大学嘉华学院' },
    { 'value': '北京科技职业学院' },
    { 'value': '北京培黎职业学院' },
    { 'value': '北京邮电大学世纪学院' },
    { 'value': '北京工业大学耿丹学院' },
    { 'value': '北京交通职业技术学院' },
    { 'value': '北京警察学院' },
    { 'value': '北京经济管理职业学院' },
    { 'value': '北京劳动保障职业学院' },
    { 'value': '北京社会管理职业学院' },
    { 'value': '北京艺术传媒职业学院' },
    { 'value': '北京第二外国语学院中瑞酒店管理学院' },
    { 'value': '北京体育职业学院' },
    { 'value': '北京交通运输职业学院' },
    { 'value': '北京卫生职业学院' },
    { 'value': '北京网络职业学院' },
    { 'value': '北京大学医学部' },
    { 'value': '北京科技大学延庆分校' },
    { 'value': '北京邮电大学宏福校区' },
    { 'value': '战略支援部队航天工程大学' },
    { 'value': '武警特种警察学院' },
    { 'value': '北京航空航天大学中法工程师学院' }
  ]
  return universities
}
